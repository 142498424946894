<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-07-05 10:04:26
-->
<template>
	<div class="dashboard">
		<div class="dashboard_left">
			<div ref="calendar" class="dashboard_left_calendar">
				<div @click="showCalendar" class="left_calendar_btn">
					<span class="left_btn_date">{{timeData.year}}年{{timeData.month}}月</span>
					<img class="left_btn_icon" :class="{'show':isCalendar}"
						src="@/assets/images/dashboard/dateArrow.png" alt="">
				</div>
				<div class="left_calendar_table">
					<!-- 日期选择弹窗 -->
					<div :class="{'date_select_show': isCalendar}" class="calendar_date_select">
						<div class="date_select_head">
							<i @click="yearSelect('cut')" class="date_select_arrow el-icon-d-arrow-left"></i>
							<span class="date_select_year">{{calendarDate.year}}年</span>
							<i @click="yearSelect('add')" class="date_select_arrow el-icon-d-arrow-right"></i>
						</div>
						<div class="date_select_box">
							<div v-for="(item, index) in monthList" :key="index" class="date_select_item">
								<span @click="monthSelect(item)"
									:class="{'date_select_active':calendarDate.year == timeData.year && calendarDate.month==item}"
									class="date_select_val">{{item}}月</span>
							</div>
						</div>
					</div>
					<!-- 日历 -->
					<div>
						<div class="left_calendar_head">
							<div v-for="(item, index) in weekList" :key="index" class="calendar_head_item">{{item}}
							</div>
						</div>
						<div class="left_calendar_content">
							<div @click="selectDay(item)" v-for="(item, index) in nowMonthDays" :key="index"
								:class="{'table_item_disable': !item.isNowMonth, 'table_item_active': timeData.month == currentMonth &&timeData.day == item.day && item.isNowMonth}"
								class="calendar_table_item">
								<div class="table_item_day">
									<!-- <span
										:class="{'date_active':timeData.day == item.day && item.isNowMonth,'date_disable': !item.isNowMonth}"
										class="table_item_val">{{timeData.day == item.day && item.isNowMonth ? '今' : item.day}}</span> -->
									<span
										:class="{'date_active':timeData.month == currentMonth &&currentDay == item.day&&item.isNowMonth,'date_disable': !item.isNowMonth}"
										class="table_item_val">{{timeData.month == currentMonth && currentDay == item.day && item.isNowMonth ? '今' : item.day}}</span>
									<img @click.stop="handlerFn('add', item)" v-if="item.isNowMonth"
										class="table_item_icon" src="@/assets/images/dashboard/plus.png" alt="">
									<img v-else class="table_item_icon" src="@/assets/images/dashboard/plusDisable.png"
										alt="">
								</div>
								<div v-if="calendarTaskList.length>0" class="table_item_task">
									<div @mouseenter="editCurrentDay(citem)" @mouseleave="outCurrentDay"
										v-for="(citem, cindex) in calendarTaskList" :key="cindex"
										class="item_task_inner">
										<div v-if="item.backlogDate==citem.backlogDate">
											<div v-if="item.isNowMonth"
												:class="{'task_finish': citem.status == '2','task_unfinish': citem.status == '1','task_wait': citem.status == 'WAIT'}"
												class="item_task_content">{{citem.title}}</div>
											<div v-else
												:class="{'task_finish_disable': citem.status == '2','task_unfinish_disable': citem.status == '1','task_wait_disable': citem.status == 'WAIT'}"
												class="item_task_content">{{citem.title}}</div>
											<div @click.stop="doubleClick"
												v-if="citem.backlogTaskId&&currentTask == citem.backlogTaskId"
												class="item_task_handler">
												<img @click.stop="handlerFn('edit', citem, cindex)"
													src="@/assets/images/dashboard/edit_white.png" class="task_edit">
												<img @click.stop="handlerFn('deleteCalendar',citem, cindex)"
													src="@/assets/images/dashboard/delete.png" class="task_delete">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 任务统计 -->
			<div class="dashboard_left_task">
				<div @click.stop="handlerFn('TaskStatistics')" class="left_task_btn">
					<img class="task_btn_icon" src="@/assets/images/dashboard/taskIcon.png" alt="">
					<span class="task_btn_text">任务统计</span>
					<img class="task_btn_arrow" src="@/assets/images/dashboard/taskArrow.png" alt="">
				</div>
				<div class="left_task_head">今日代办</div>
				<div class="left_task_box">
					<div class="left_task_title">
						<span class="task_title_point"></span>
						<span class="task_title_val">今日未完成任务</span>
					</div>
					<div class="left_task_content" v-for="(item, index) in taskLists.no" :key="item.backlogTaskId">
						<div class="left_task_item task_unfinish" @mouseenter="editTask(item)" @mouseleave="outTask">
							<span>{{item.title}}</span>
							<div @click="doubleClick" class="task_item_handle"
								v-if="item.backlogTaskId&&taskId == item.backlogTaskId">
								<img @click.stop="handlerFn('edit', item, index)"
									src="@/assets/images/dashboard/edit_white.png" class="task_edit">
								<img @click.stop="handlerFn('deleteToday', item, index)"
									src="@/assets/images/dashboard/delete.png" class="task_delete">
							</div>
						</div>
					</div>
					<!-- 	<div v-if="!taskLists.no || taskLists.no.length == 0" class="left_task_empty">
						<img src="@/assets/images/common/empty1.png">
						<span>暂无未完成任务</span>
					</div> -->
					<div class="left_task_title">
						<span class="task_title_point"></span>
						<span class="task_title_val">今日已完成任务</span>
					</div>
					<div class="left_task_content" v-for="(item, index) in taskLists.yes" :key="item.backlogTaskId"
						@click.stop="handlerFn('detail',item)">
						<div class="left_task_item"
							:class="{'task_wait': item.source == 2, 'task_finish': item.source == 1}">{{item.title}}
						</div>
					</div>
					<!-- <div v-if="!taskLists.yes || taskLists.yes.length == 0" class="left_task_empty">
						<img src="@/assets/images/common/empty1.png">
						<span>暂无未完成任务</span>
					</div> -->
				</div>
			</div>
		</div>
		<div class="dashboard_right">
			<div class="dashboard_right_student">
				<div class="right_student_title">学生状态</div>
				<student-list :studentIndex="studentIndex" :intList="studentList" @selectStudent="selectStudent" @searchStudent="searchStudent" />
			</div>
			<div class="dashboard_right_content">
				<div class="right_content_tab">
					<span class="content_tab_btn" v-if="personData.appStatus==1">申请季</span>
					<span class="content_tab_btn" v-if="personData.appStatus==2">规划期</span>
					<span class="content_tab_btn" v-if="personData.appStatus==3">已完成</span>
					<div class="content_tab_list">
						<span @click="changeTab(item)" :class="{'tab_item_active': currentTab == item.type}"
							v-for="(item, index) in tabList" :key="index" class="content_tab_item">{{item.title}}</span>
					</div>
				</div>
				<!-- 完成度 -->
				<div class="" v-if="currentTab == 1 || currentTab == 3">
					<div class="right_content_radio">
						<radioGroup :isCheckBox="false" @checkRadio="checkRadio" :radioList="radioList"
							:currentRadio="status" />
					</div>
					<!-- <div class="right_content_precent">
						<span class="content_precent_item precent_item_green">已完成 ({{homeListData.taskYesCount}})</span>
						<span class="content_precent_item precent_item_yellow">未完成 ({{homeListData.taskNoCount}})</span>
					</div> -->
				</div>
				<!-- 文书 -->
				<div v-if="currentTab == 1">
					<div class="right_content_precent">
						<span v-if="getDataPercent(writeFileList.doingCount, writeFileList.finishCount, 'first') !='0%'"
							:style="{'width': getDataPercent(writeFileList.doingCount, writeFileList.finishCount, 'first')}"
							class="content_precent_item precent_item_yellow">{{writeFileList.doingCount}}</span>
						<span
							v-if="getDataPercent(writeFileList.doingCount, writeFileList.finishCount, 'second') !='0%'"
							:style="{'width': getDataPercent(writeFileList.doingCount, writeFileList.finishCount, 'second')}"
							class="content_precent_item precent_item_green">{{writeFileList.finishCount}}</span>
					</div>
					<div class="right_content_university">
						<div v-if="writeFileList.schoolSelections&&writeFileList.schoolSelections.length>0">
							<div v-for="(item,index) in writeFileList.schoolSelections" :key="index">
								<div @click="handlerFn('toWritList',item)" class="content_university_item">
									<div class="b-b">
										<div class="write-box">
											<div>
												<div class="university_item_name">{{item.name}}</div>
												<div class="university_item_time">
													<span class="university_item_label">更新时间</span>
													<span
														class="university_item_value">{{item.lastUpdateTime?item.lastUpdateTime:"-"}}</span>
												</div>
											</div>
											<div class="item_bottom_right">
												<div class="university_item_percent"
													:class="{'green':item.progressRate==100,'red':item.progressRate<100}">
													{{item.progressRate||0}}%</div>
												<div class="university_item_text">完成进度</div>
											</div>
										</div>
										<div @click.stop="showMoreViews(item)" class="views_item_more mt">
											<span>{{item.showMore==true? '收起' : '展开'}}</span>
											<img src="@/assets/images/dashboard/moreArrow.png"
												:class="{'showMore':item.showMore}">
										</div>
									</div>
									<div class="university_item_bottom b-b" v-if="item.showMore">
										<div class="item_bottom_left" v-for="ite in item.schoolPaperworks"
											:key="ite.id">
											<div>
												<div style="padding-bottom: 15px;" class="university_item_time">
													<span class="university_item_label">文书标题</span>
													<span class="university_item_value">{{ite.paperworkTitle}}</span>
												</div>
												<div style="padding-bottom: 15px;" class="university_item_time">
													<span class="university_item_label">开始时间</span>
													<span
														class="university_item_value">{{ite.createTime?ite.createTime:"-"}}</span>
												</div>
												<div class="university_item_time">
													<span class="university_item_label">更新时间</span>
													<span
														class="university_item_value">{{item.lastUpdateTime?item.lastUpdateTime:"-"}}</span>
												</div>
											</div>
											<div class="item_bottom_right">
												<div class="university_item_percent fs green"
													v-if="ite.progressRate==100">已完成</div>
												<div class="university_item_percent fs red" v-if="ite.progressRate<=50">
													待完成</div>
												<!-- <div class="university_item_percent fs" v-if="ite.progressRate==0">未开始</div> -->
												<div class="university_item_text">完成进度</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="right_content_empty">
							<img src="@/assets/images/common/empty1.png">
							<span>{{status == 1?'暂无进行中文书':'暂无已完成文书'}}</span>
						</div>
					</div>
				</div>
				<!-- 信息 -->
				<div v-if="currentTab == 2">
					<div class="right_views">
						<div class="right_views_item" v-for="(item,index) in acControlData" :key="index"
							@click="updateMessage(item)">
							<div class="views_item_top">
								<div class="views_item_name flex-between">
									<span class="item_name_left">{{item.name}}
										<img v-if="item.hasUpdate==1" src="../../assets/images/common/new.png"
											class="student_item_new">
										<!-- <span class="item_bottom_icon" v-if="item.hasUpdate==1">新</span> -->
									</span>
									<span class="item_name_right agree"
										v-if="item.common.result=='Admit'">{{item.common.result}}</span>
									<span class="item_name_right red"
										v-if="item.common.result=='Waitlist'">{{item.common.result}}</span>
									<span class="item_name_right yel"
										v-if="item.common.result=='Reject'">{{item.common.result}}</span>
								</div>
								<div class="views_item_content">
									<div class="item_content_row flex_item">
										<div class="content_row_item">
											<span class="row_item_label">申请批次:</span>
											<span class="row_item_value">{{item.batch||'-'}}</span>
										</div>
										<div class="content_row_item">
											<span class="row_item_label">专业:</span>
											<span class="row_item_value">{{item.profession||'-'}}</span>
										</div>
									</div>
									<div class="item_content_row flex_item">
										<div class="content_row_item">
											<span class="row_item_label">截止日期:</span>
											<span class="row_item_value">{{item.end||'-'}}</span>
										</div>
										<div class="content_row_item">
											<span class="row_item_label">自定截止:</span>
											<span class="row_item_value">{{item.customEnd||'-'}}</span>
										</div>
									</div>
									<div class="item_content_row flex-between">
										<div class="content_row_item" v-if="item.common.time">
											<span class="row_item_label">申请提交时间:</span>
											<span class="row_item_value">{{item.common.time||'-'}}</span>
										</div>
										<div class="content_row_status" v-if="item.common.state=='已提交'">
											申请已提交
										</div>
										<div class="content_row_status" v-if="item.common.state=='待提交'">
											申请待提交
										</div>
									</div>
								</div>
								<div @click.stop="showMoreViews(item)" class="views_item_more">
									<span>{{item.showMore==true? '收起' : '展开'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png"
										:class="{'showMore':item.showMore}">
								</div>
							</div>
							<div v-if="item.showMore" class="views_item_bottom">
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">标化:</span>
										<span v-if="item.standardize.count>0">
											<span class="item_bottom_tag"
												v-if="item.standardize.count==item.standardize.finish">已完成{{item.standardize.finish}}/{{item.standardize.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.standardize.finish}}/{{item.standardize.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.standardize.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.standardize.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">成绩单:</span>
										<span v-if="item.score.count>0">
											<span class="item_bottom_tag"
												v-if="item.score.count==item.score.finish">已完成{{item.score.finish}}/{{item.score.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.score.finish}}/{{item.score.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.score.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.score.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">推荐信:</span>
										<span v-if="item.recommendation.count>0">
											<span class="item_bottom_tag"
												v-if="item.recommendation.count==item.recommendation.finish">已完成{{item.recommendation.finish}}/{{item.recommendation.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.recommendation.finish}}/{{item.recommendation.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.recommendation.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.recommendation.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">资金证明:</span>
										<span v-if="item.funds.count>0">
											<span class="item_bottom_tag"
												v-if="item.funds.count==item.funds.finish">已完成{{item.funds.finish}}/{{item.funds.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.funds.finish}}/{{item.funds.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.funds.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.funds.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<!-- <div class="item_bottom_icon">
											<span>新</span>
										</div> -->
										<span class="item_bottom_label">面试:</span>
										<span v-if="item.interview.count>0">
											<span class="item_bottom_tag"
												v-if="item.interview.count==item.interview.finish">已完成{{item.interview.finish}}/{{item.interview.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.interview.finish}}/{{item.interview.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.interview.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.interview.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">附加材料:</span>
										<span v-if="item.extramaterial.count>0">
											<span class="item_bottom_tag"
												v-if="item.extramaterial.count==item.extramaterial.finish">已完成{{item.extramaterial.finish}}/{{item.extramaterial.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.extramaterial.finish}}/{{item.extramaterial.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.extramaterial.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.extramaterial.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">文书:</span>
										<span v-if="item.paperwork.count>0">
											<span class="item_bottom_tag"
												v-if="item.paperwork.count==item.paperwork.finish">已完成{{item.paperwork.finish}}/{{item.paperwork.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.paperwork.finish}}/{{item.paperwork.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.paperwork.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.paperwork.updateTime}}</span>
									</div>
								</div>
								<div class="item_bottom_row">
									<div class="flex_item">
										<span class="item_bottom_label">邮寄:</span>
										<span v-if="item.email.count>0">
											<span class="item_bottom_tag"
												v-if="item.email.count==item.email.finish">已完成{{item.email.finish}}/{{item.email.count}}</span>
											<span class="item_bottom_tag yellow"
												v-else>进行中{{item.email.finish}}/{{item.email.count}}</span>
										</span>
										<span class="item_bottom_tag gray" v-else>-</span>
									</div>
									<div class="item_bottom_time flex_item" v-if="item.email.updateTime">
										<span style="margin-right: 10px;">最近更新:</span>
										<span>{{item.email.updateTime}}</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!acControlData || acControlData.length == 0" class="right_content_empty">
							<img src="@/assets/images/common/empty1.png">
							<span>暂无信息</span>
						</div>
					</div>
				</div>
				<!-- 任务 -->
				<div v-if="currentTab == 3">
					<div class="right_content_precent">
						<span v-if="getDataPercent(homeListData.taskNoCount, homeListData.taskYesCount, 'first') !='0%'"
							:style="{'width': getDataPercent(homeListData.taskNoCount, homeListData.taskYesCount, 'first')}"
							class="content_precent_item precent_item_yellow">{{homeListData.taskNoCount}}</span>
						<span
							v-if="getDataPercent(homeListData.taskNoCount, homeListData.taskYesCount, 'second') !='0%'"
							:style="{'width': getDataPercent(homeListData.taskNoCount, homeListData.taskYesCount, 'second')}"
							class="content_precent_item precent_item_green">{{homeListData.taskYesCount}}</span>
					</div>
					<div class="right_task">
						<div v-if="homeListData.viewTaskResponses&&homeListData.viewTaskResponses.length>0">
							<div @click="handlerFn('showTask',item)" class="right_task_item"
								v-for="(item,index) in homeListData.viewTaskResponses" :key="index">
								<div class=" flex-between">
									<span class="item_top_title">{{item.title}}</span>
									<span class="item_top_status" v-if="status==1">进行中</span>
									<span class="item_top_status green" v-if="status==2">已完成</span>
								</div>
								<div class="task_item_bottom task_item_top flex_item" v-if="status==1">
									<span class="item_bottom_label">任务周期</span>
									<span class="item_bottom_time"
										v-if="status==1">{{item.completeDateStart}}至{{item.completeDateEnd}}</span>
								</div>
								<div v-if="status==2">
									<div class="mt">
										<div class="task_item_bottom flex_item">
											<span class="item_bottom_label">任务发布时间</span>
											<span class="item_bottom_time">{{item.createTime}}</span>
										</div>
										<div class="task_item_bottom flex_item">
											<span class="item_bottom_label">任务完成时间</span>
											<span class="item_bottom_time">{{item.updateTime}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!homeListData.viewTaskResponses || homeListData.viewTaskResponses.length == 0"
							class="right_content_empty">
							<img src="@/assets/images/common/empty1.png">
							<span>{{status == 1?'暂无进行中任务':'暂无已完成任务'}}</span>
						</div>
						<!-- <div class="right_task_item">
							<div class="task_item_top flex-between">
								<span class="item_top_title">专注考试1</span>
								<span class="item_top_status green">已完成</span>
							</div>
							<div class="task_item_bottom flex_item">
								<span class="item_bottom_label">预计完成时间</span>
								<span class="item_bottom_time">2022—01—12至2022—1—14</span>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<handle-form ref="handlerForm" @closeDialog="closeDialog" :isOpen="diaLogVisible" :diaLogTitle="diaLogTitle"
			:diaLogType="diaLogType" />
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
		<!-- 任务详情 -->
		<task-form ref="taskForm" :studentId="relatedObjId" @taskEditSuccess="taskEditSuccess" />
	</div>
</template>
<script>
	import studentList from "@/components/studentList/index.vue";
	import handleForm from "./components/handleForm.vue";
	import radioGroup from "@/components/radioGroup/index.vue";
	import taskForm from "../taskProgress/components/taskForm.vue";
	import {
		listStudents,
		personalViewStudents
	} from "@/api/common";
	import {
		getTaskList,
		delTask,
		addTask,
		queryTask,
		calendarTask,
		listTaskHome,
		listSy,
		readMessage
	} from "@/api/dashboards";
	import {
		acList
	} from "@/api/acTable";
	import {
		listSchoolWritOfschool
	} from "@/api/applicationProgress";
	import {
		mapActions
	} from "vuex";
	import storage from 'store';
	export default {
		components: {
			studentList,
			handleForm,
			radioGroup,
			taskForm
		},
		data() {
			return {
				timeData: {
					year: "",
					month: "",
					day: ""
				},
				currentDay: undefined,
				currentYear: undefined,
				currentMonth: undefined,
				weekList: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
				monthList: [
					"1",
					"2",
					"3",
					"4",
					"5",
					"6",
					"7",
					"8",
					"9",
					"10",
					"11",
					"12"
				],
				monthSizeList: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
				nowMonthDays: [],
				calendarTaskList: [],
				calendarDate: {
					year: "",
					month: ""
				},
				isCalendar: false,
				studentList: [],
				tabList: [{
						type: 1,
						title: "文书"
					},
					{
						type: 2,
						title: "信息"
					},
					{
						type: 3,
						title: "任务"
					}
				],
				currentTab: 1,
				radioList: [{
						label: "1",
						value: "进行中",
						check: true
					},
					{
						label: "2",
						value: "已完成",
						check: false
					}
				],
				currentStatus: 1,
				showMore: false,
				currentTask: null,
				diaLogVisible: false,
				diaLogTitle: "",
				diaLogType: "",
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				deleteType: "",
				deleteIndex: undefined,
				clickTime1: 0,
				clickTime2: 0,
				studentId: "",
				taskId: "",
				taskLists: {},
				backlogTaskId: "",
				personData: {},
				status: "1",
				homeListData: {},
				writeFileList: [], //文书列表
				acControlData: [], //信息数据
				relatedObjId: "",
				studentIndex: 0
			};
		},
		watch: {
			diaLogVisible: {
				handler(val) {
					if (!val) {
						this.$refs.handlerForm.handleType = "";
					}
				}
			}
		},
		computed: {
			calendarHeight() {
				this.$nextTick(() => {
					console.log("日历高度>>>>>>>", this.$refs.calendar.offsetHeight);
				});
			}
		},
		mounted() {},
		async created() {
			this.getCurrentDate("origin");
			await this.studentLists();
			this.getTaskListData();
			this.schoolWritListOfschool();
			// this.getAcLists()
		},
		methods: {
			...mapActions(["studentUpdateClear"]),
			taskEditSuccess() {
				this.studentPersonalView();
			},
			getCharFromUtf8(str) {
				var cstr = "";
				var nOffset = 0;
				if (str == "") return "";
				str = str.toLowerCase();
				nOffset = str.indexOf("%e");
				if (nOffset == -1) return str;
				while (nOffset != -1) {
					cstr += str.substr(0, nOffset);
					str = str.substr(nOffset, str.length - nOffset);
					if (str == "" || str.length < 9) return cstr;
					cstr += this.utf8ToChar(str.substr(0, 9));
					str = str.substr(9, str.length - 9);
					nOffset = str.indexOf("%e");
				}
				return cstr + str;
			},
			utf8ToChar(str) {
				var iCode, iCode1, iCode2;
				iCode = parseInt("0x" + str.substr(1, 2));
				iCode1 = parseInt("0x" + str.substr(4, 2));
				iCode2 = parseInt("0x" + str.substr(7, 2));
				return String.fromCharCode(
					((iCode & 0x0f) << 12) | ((iCode1 & 0x3f) << 6) | (iCode2 & 0x3f)
				);
			},
			// 获取任务日历
			taskCalendar() {
				return new Promise((resolve, reject) => {
					let prams = {
						backlogDate: this.timeData.year +
							"-" +
							this.timeData.month +
							"-" +
							this.timeData.day +
							" " +
							"00:00:00"
					};
					calendarTask(prams).then(res => {
						if (res.code == 0) {
							res.data.forEach(item => {
								if (!item.attNamq && item.attPath) {
									let lineIndex = item.attPath.lastIndexOf("/");
									let fileName = item.attPath.substring(
										lineIndex + 1,
										item.attPath.length
									);
									fileName = this.getCharFromUtf8(fileName)
									this.$set(item, "attNamq", fileName)
								}
							});
							this.calendarTaskList = res.data;
							// console.log("calendarTaskList", this.calendarTaskList)

							resolve(this.calendarTaskList);
						} else {
							reject();
						}
					});
				});
			},
			//点击首页已读
			updateMessage(item) {
				var params = {
					schoolId: item.schoolId,
					studentId: item.studentId
				};
				readMessage(params).then(res => {
					if (res.code == 0) {
						this.getAcLists();
						this.$router.push({
							path: "/applicationProgress?type=2&&studentId=" + this.studentId
						});
					}
				});
			},
			//获取学生列表
			studentLists() {
				return new Promise((resolve, reject) => {
					var params = {
						name: this.studentName
					};
					listStudents(params).then(res => {
						if (res.code == 0) {
							if (res.data && res.data.length > 0) {
								this.studentList = res.data;
								// if(storage.get('studentIndex')) {
								// 	this.studentIndex = storage.get('studentIndex') * 1
								// }
								// this.studentId = res.data[this.studentIndex].studentAccountId;
								if(storage.get('studentId')) {
									let Index = res.data.findIndex(item => item.studentAccountId == storage.get('studentId'))
									// console.log(Index,Index)
									if(Index==-1){
										this.studentIndex = 0
										this.studentId = res.data[this.studentIndex].studentAccountId
									}else{
										this.studentId = storage.get('studentId')
										this.studentIndex = res.data.findIndex(item => item.studentAccountId == this.studentId)
									}
								}else{
										if(storage.get('studentIndex')) {
											this.studentIndex = storage.get('studentIndex') * 1
										}
										this.studentId = res.data[this.studentIndex].studentAccountId;
								}
								if (this.studentList[this.studentIndex].updateNotifyId) {
									this.studentUpdateClear({
										updateNotifyId: this.studentList[this.studentIndex].updateNotifyId
									}).then(res => {
										if (res.code == 0) {
											this.studentList[this.studentIndex].updateNotifyId = undefined;
											this.$forceUpdate();
										}
									});
								}
								this.studentPersonalView();
								resolve(this.studentList);
								resolve(this.studentId);
							}
						} else {
							reject();
						}
					});
				});
			},
			// 学生个人资料
			studentPersonalView() {
				if (!this.studentId) return;
				let params = {
					studentInfoId: this.studentId
				};
				personalViewStudents(params).then(res => {
					if (res.code == 0) {
						this.personData = res.data;
						console.log(this.personData);
						// if (this.personData.appStatus == 2) {
						// 	this.tabList = [{
						// 		type: 3,
						// 		title: "任务"
						// 	}]
						// } else {
						// 	this.tabList = [{
						// 			type: 1,
						// 			title: "文书"
						// 		},
						// 		{
						// 			type: 2,
						// 			title: "信息"
						// 		},
						// 		{
						// 			type: 3,
						// 			title: "任务"
						// 		}
						// 	]
						// }
						if (this.currentTab == 3) {
							this.getHomeTaskList();
						}
					}
					console.log(this.personData, "1");
				});
			},
			searchStudent(data) {
				this.studentName = data;
				this.studentLists();
			},
			//获取学生任务
			getHomeTaskList() {
				if (!this.studentId) return;
				console.log(this.personData, 2);
				var params = {
					appStatus: this.personData.appStatus,
					status: this.status,
					studentInfoId: this.studentId
				};
				listTaskHome(params).then(res => {
					if (res.code == 0) {
						this.homeListData = res.data;
						this.radioList.forEach(item => {
							if (item.label == 1) {
								item.value = `进行中(${res.data["taskNoCount"]})`;
							} else {
								item.value = `已完成(${res.data["taskYesCount"]})`;
							}
						});
					}
				});
			},
			//获取今日待办记录
			getTaskListData() {
				getTaskList().then(res => {
					if (res.code == 0) {
						if (res.data) {
							this.taskLists = res.data;
						}
					}
				});
			},
			// 获取数据比例
			getDataPercent(data1, data2, which) {
				if (data1 && data2) {
					let total = data1 * 1 + data2 * 1;
					// console.log("getDataPercent1", data1, data2, total)
					if (total > 0) {
						let percent1 = (data1 * 1 / total).toFixed(2) * 100;
						let percent2 = 100 - percent1;
						// console.log("getDataPercent2", data1, data2, percent1, percent2)
						if (which == "first") {
							return percent1 + "%";
						} else {
							return percent2 + "%";
						}
					} else {
						if (which == "first") {
							return "50%";
						} else {
							return "50%";
						}
					}
				} else {
					if (which == "first") {
						return "50%";
					} else {
						return "50%";
					}
				}
			},
			//学校文书-学校列表
			schoolWritListOfschool() {
				return new Promise((resolve, reject) => {
					if (!this.studentId) return;
					let params = {
						studentId: this.studentId,
						type: this.status
					};
					listSy(params).then(res => {
						if (res.code == 0) {
							if (res.data && JSON.stringify(res.data) != "{}") {
								if (res.data.schoolSelections.length > 0) {
									res.data.schoolSelections.forEach(el => {
										el.showMore = false;
										if (el.commonPaperworks) {
											el.schoolPaperworks = el.commonPaperworks;
										}
									});
								}
								this.writeFileList = res.data;
								this.radioList.forEach(item => {
									if (item.label == 1) {
										item.value = `进行中(${res.data["doingCount"]})`;
									} else {
										item.value = `已完成(${res.data["finishCount"]})`;
									}
								});
							} else {
								this.writeFileList.doingCount = 0;
								this.writeFileList.finishCount = 0;
								this.writeFileList.schoolPaperworks = [];
								this.radioList.forEach(item => {
									if (item.label == 1) {
										item.value = `进行中(${0})`;
									} else {
										item.value = `已完成(${0})`;
									}
								});
							}
						}
					});
				});
			},
			//获取信息数据
			getAcLists() {
				if (!this.studentId) return;
				var params = {
					studentId: this.studentId
				};
				acList(params).then(res => {
					if (res.code == 0) {
						res.data.records.forEach(item => {
							item.showMore = false;
						});
						this.acControlData = res.data.records;
					}
				});
			},
			selectStudent(props) {
				this.studentId = props.studentAccountId;
				this.studentIndex = this.studentList.findIndex(item => item.studentAccountId ==
					this.studentId)
				storage.set('studentIndex', this.studentIndex)
				storage.set('studentId', this.studentId)
				this.studentPersonalView();
				if (this.currentTab == 1) {
					this.schoolWritListOfschool();
				} else if (this.currentTab == 2) {
					this.getAcLists();
				} else if (this.currentTab == 3) {
					this.studentPersonalView();
				}
			},
			changeTab(item) {
				this.currentTab = item.type;
				switch (item.type) {
					case 1:
						this.status = "1";
						this.schoolWritListOfschool();
						break;
					case 2:
						this.getAcLists();
						break;
					case 3:
						this.status = "1";
						// this.getHomeTaskList()
						this.studentPersonalView();
						break;
				}
			},
			changeRadio(item) {
				this.currentStatus = item.status;
			},
			yearSelect(type) {
				switch (type) {
					case "cut":
						this.calendarDate.year--;
						break;
					case "add":
						this.calendarDate.year++;
						break;
				}
			},
			async monthSelect(item) {
				this.calendarDate.month = item;
				this.timeData.year = this.calendarDate.year;
				this.timeData.month = item;
				this.getCurrentDate();
				await this.taskCalendar();
				this.getPrevMonthDays();
				this.isCalendar = false;
			},
			// 获取年月日
			async getCurrentDate(type) {
				if (type == "origin") {
					let date = new Date();
					this.currentYear = date.getFullYear();
					this.currentMonth = date.getMonth() + 1;
					this.currentDay = date.getDate();
					this.timeData.year = date.getFullYear();
					this.calendarDate.year = date.getFullYear();
					this.timeData.month = date.getMonth() + 1;
					this.calendarDate.month = date.getMonth() + 1;
					this.timeData.day = date.getDate();
					await this.taskCalendar();
					this.getPrevMonthDays();
				}
				// 判断闰年
				if (
					(this.timeData.year % 4 == 0 && this.timeData.year % 100 != 0) ||
					this.timeData.year % 400 == 0
				) {
					this.monthSizeList[1] = 29;
				}
			},
			// 获取上个月剩余天数
			getPrevMonthDays() {
				this.nowMonthDays = [];
				// 获取当月天数
				let nowMonthSize = this.monthSizeList[this.timeData.month * 1 - 1];
				console.log(this.nowMonthDays, "nowMonthDays");
				this.nowMonthDays = [];
				let isNowMonth = false;
				if (
					this.timeData.month == this.currentMonth &&
					this.timeData.year == this.currentYear
				) {
					isNowMonth = true;
				}
				for (let i = 1; i <= nowMonthSize; i++) {
					this.nowMonthDays.push({
						backlogDate: this.timeData.year +
							"-" +
							this.addZero(this.timeData.month) +
							"-" +
							this.addZero(i),
						isNowMonth: true,
						day: i
					});
				}
				console.log("calendarTaskList", this.nowMonthDays);
				// for (var i = 1; i <= nowMonthSize; i++) {
				// 	if (i == 3 || i == 5 || i == 7 || i == 9 || i == 12 || i == 18) {
				// 		this.nowMonthDays.push({
				// 			isNowMonth: true,
				// 			day: i,
				// 			val: [{
				// 					status: "FINISH",
				// 					content: "专注考试专注考试",
				// 					id: Number(Math.random().toString().substr(3, 10) + Date.now()).toString(
				// 						36),
				// 				},
				// 				{
				// 					status: "WAIT",
				// 					content: "专注考试专注考试",
				// 					id: Number(Math.random().toString().substr(3, 10) + Date.now()).toString(
				// 						36),
				// 				},
				// 				{
				// 					status: "UNFINISH",
				// 					content: "专注考试专注考试",
				// 					id: Number(Math.random().toString().substr(3, 10) + Date.now()).toString(
				// 						36),
				// 				}
				// 			]
				// 		});
				// 	} else {
				// 		this.nowMonthDays.push({
				// 			isNowMonth: true,
				// 			day: i
				// 		});
				// 	}
				// }
				// 获取本月第一天星期几
				let nowMonthFirst = new Date(
					`${this.timeData.year}/${this.timeData.month}/1`
				).getDay();
				// 当前一号前剩余多少天
				let prevMonthDays = nowMonthFirst == 7 ? 0 : nowMonthFirst;
				let prevMonthSize =
					this.timeData.month == 1 ?
					31 :
					this.monthSizeList[this.timeData.month - 2];
				if (prevMonthDays > 0) {
					let prevArr = [];
					for (let i = 1; i <= prevMonthDays; i++) {
						prevArr.push({
							isNowMonth: false,
							day: prevMonthSize * 1 - prevMonthDays * 1 + i * 1
						});
					}
					// console.log("prevArr", prevArr)
					this.nowMonthDays = prevArr.concat(this.nowMonthDays);
				}
				// 获取本月最后一天星期几
				let nowMonthLast = new Date(
					`${this.timeData.year}/${this.timeData.month}/${nowMonthSize}`
				).getDay();
				// 获取最后一天还剩几天
				let nextMonthDays = nowMonthLast == 7 ? 6 : 6 - nowMonthLast;
				for (let i = 1; i <= nextMonthDays; i++) {
					this.nowMonthDays.push({
						isNowMonth: false,
						day: i
					});
				}
				// console.log("nowMonthDays", this.nowMonthDays);
			},
			addZero(num) {
				if (num < 10) return "0" + num;
				return num;
			},
			showCalendar() {
				// console.log(this.isCalendar)
				this.isCalendar = !this.isCalendar;
			},
			showMoreViews(item) {
				item.showMore = !item.showMore;
			},
			editCurrentDay(item) {
				this.currentTask = item.backlogTaskId;
			},
			outCurrentDay() {
				this.currentTask = null;
			},
			editTask(item) {
				this.taskId = item.backlogTaskId;
			},
			outTask() {
				this.taskId = "";
			},
			checkRadio(props) {
				this.status = props.label;
				if (this.currentTab == 1) {
					this.schoolWritListOfschool();
				} else if (this.currentTab == 3) {
					this.getHomeTaskList();
				}
			},
			// 鼠标双击编辑
			doubleClick() {
				try {
					if (this.clickTime1 == 0) {
						this.clickTime1 = new Date().getTime();
						setTimeout(() => {
							if (this.clickTime2 == 0) {
								console.log("单击事件");
								this.clickTime1 = 0;
							}
						}, 310);
					} else {
						this.clickTime2 = new Date().getTime();
						if (this.clickTime2 - this.clickTime1 <= 300) {
							console.log("这是双击");
							// this.diaLogType = "waitEdit"
							// this.diaLogTitle = "编辑待办"
							// this.diaLogVisible = true
							// this.$nextTick(() => {
							// 	this.$refs.handlerForm.texcherList()
							// 	this.$refs.handlerForm.handleType = "edit"
							// })
						}
						this.clickTime1 = 0;
						setTimeout(() => {
							this.clickTime2 = 0;
						}, 320);
					}
				} catch (e) {
					console.log(e);
				}
			},
			selectDay(item) {
				// console.log("selectDay", item)
				// this.timeData.day = item.day
				// console.log("selectDay", this.timeData)
				// this.getTaskListData()
			},
			handlerFn(type, item, index) {
				console.log("handlerFn", item);
				switch (type) {
					case "add":
						this.diaLogType = "waitEdit";
						this.diaLogTitle = "添加待办";
						this.$nextTick(() => {
							this.$refs.handlerForm.texcherList();
							this.$refs.handlerForm.handleType = "add";
							this.$refs.handlerForm.diaLogDate =
								this.timeData.year + "-" + this.timeData.month + "-" + item.day;
						});
						this.diaLogVisible = true;
						break;
					case "edit":
						this.diaLogType = "waitEdit";
						this.diaLogTitle = "编辑待办";
						this.$nextTick(() => {
							this.$refs.handlerForm.texcherList();
							this.$refs.handlerForm.getTaskDetail(item.backlogTaskId);
						});
						this.$refs.handlerForm.handleType = "edit";
						this.diaLogVisible = true;
						this.backlogTaskId = item.backlogTaskId;
						break;
					case "deleteCalendar":
						this.messegeVisible = true;
						this.messegeTip = "信息提示";
						this.messegeContent = "确定删除当前选中待办任务内容吗？";
						this.messegeType = "warn";
						this.deleteType = "deleteCalendar";
						this.backlogTaskId = item.backlogTaskId;
						// console.log("删除任务",index, index)
						this.deleteIndex = index;
						break;
					case "deleteToday":
						this.messegeVisible = true;
						this.messegeTip = "信息提示";
						this.messegeContent = "确定删除当前选中待办任务内容吗？";
						this.messegeType = "warn";
						this.deleteType = "deleteToday";
						this.backlogTaskId = item.backlogTaskId;
						// console.log("删除任务",index)
						this.deleteIndex = index;
						break;
					case "detail":
						this.diaLogType = "waitDetail";
						this.diaLogTitle = "待办详情";
						this.$nextTick(() => {
							this.$refs.handlerForm.getTaskDetail(item.backlogTaskId);
						});
						this.diaLogVisible = true;
						break;
					case "TaskStatistics":
						this.$router.push({
							path: "/dashboard/taskStatistics"
						});
						break;
					case "toWritList":
						this.$router.push({
							path: "/applicationProgress?type=3&&studentId=" + this.studentId
						});
						break;
					case "showTask":
						console.log("showTask", item);
						this.relatedObjId = item.viewTaskId;
						this.$nextTick(() => {
							this.$refs.taskForm.taskType = "monthviewClassTaskEdit";
							this.$refs.taskForm.diaLogTitle = "任务详情";
							this.$refs.taskForm.viewsTaskDetail();
							this.$refs.taskForm.diaLogVisible = true;
						});
						break;
				}
			},
			//代办详情

			closeDialog() {
				this.diaLogVisible = false;
			},
			delTaskData() {
				delTask({
					backlogTaskId: this.backlogTaskId
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: "待办任务删除成功~",
							type: "success"
						});
						if (this.deleteType == "deleteCalendar") {
							this.calendarTaskList.splice(this.deleteIndex, 1);
						} else {
							this.taskLists.no.splice(this.deleteIndex, 1);
						}
					}
				});
				this.messegeVisible = false;
			},
			confirmMessege() {
				this.delTaskData();
			},
			closeMessege() {
				this.messegeVisible = false;
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	/deep/.table_item_task::-webkit-scrollbar {
		display: none;
	}

	.student_item_new {
		width: 16px;
		height: 16px;
		margin-left: 8px;
	}

	.green {
		color: #5ba897;
	}

	.red {
		color: #d0743b;
	}

	.gray {}

	.task_finish {
		background: rgba(238, 246, 244, 1);
		border-left: 1px solid $theme_color;
		color: $theme_color;
	}

	.task_finish_disable {
		background: rgba(238, 246, 244, 0.39);
		border-left: 1px solid rgba(91, 168, 151, 0.39);
		color: rgba(91, 168, 151, 0.39);
	}

	.task_unfinish {
		background: rgba(250, 241, 235, 1);
		border-left: 1px solid rgba(208, 116, 59, 1);
		color: rgba(208, 116, 59, 1);
	}

	.task_unfinish_disable {
		background: rgba(250, 241, 235, 0.39);
		border-left: 1px solid rgba(208, 116, 59, 0.39);
		color: rgba(208, 116, 59, 0.39);
	}

	.task_wait {
		background: rgba(247, 245, 241, 1);
		border-left: 1px solid rgba(179, 154, 119, 1);
		color: rgba(179, 154, 119, 1);
	}

	.task_wait_disable {
		background: rgba(247, 245, 241, 0.39);
		border-left: 1px solid rgba(179, 154, 119, 0.39);
		color: rgba(179, 154, 119, 0.39);
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.dashboard {
		// width: 1880px;
		border-radius: 10px 10px 10px 0px;
		padding: 24px 20px;
		display: flex;
		background: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		max-height: 980px;
		margin-top: 4px;
		border-top: 20px solid #f7f7f7;

		.dashboard_left {
			// width: 59%;
			margin-right: 10px;
			padding: 15px 20px;
			border: 1px solid #eeeeee;
			border-radius: 4px;
			display: flex;
			box-sizing: border-box;

			.dashboard_left_calendar {
				width: 865px;
				// width: 78%;
				display: flex;
				flex-direction: column;

				.left_calendar_btn {
					width: 159px;
					height: 42px;
					background: rgba(255, 255, 255, 0.39);
					box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.16);
					border-radius: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					.left_btn_date {
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						margin-right: 11px;
					}

					.left_btn_icon {
						width: 18px;
						height: 18px;
						display: flex;
						align-items: center;
						justify-content: center;

						&.show {
							transform: rotateX(180deg);
							-webkit-transform: rotateX(180deg);
						}
					}
				}

				.left_calendar_table {
					margin-top: 15px;
					display: flex;
					flex-direction: column;
					position: relative;

					.calendar_date_select {
						position: absolute;
						left: 0;
						top: 0;
						width: 300px;
						height: 0px;
						box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
						background: #fff;
						transition: all 0.1s ease-in;
						overflow: hidden;
						z-index: 2000;

						.date_select_head {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 12px 12px;
							margin: 0 12px;
							border-bottom: 1px solid #ebeef5;

							.date_select_arrow {
								font-size: 14px;
								cursor: pointer;
							}

							.date_select_arrow:hover {
								color: $theme_color;
							}

							.date_select_year {
								flex: 1;
								font-size: 16px;
								text-align: center;
							}
						}

						.date_select_box {
							padding: 0px 12px;
							display: flex;
							flex-wrap: wrap;

							.date_select_item {
								width: 69px;
								height: 72px;
								display: flex;
								align-items: center;
								justify-content: center;

								.date_select_val {
									width: 50px;
									line-height: 50px;
									font-size: 14px;
									border-radius: 50%;
									cursor: pointer;
									text-align: center;

									&.date_select_active {
										background: $theme_color;
										color: #fff;
									}
								}

								.date_select_val:hover {
									background: rgba(91, 168, 151, 0.3);
									color: #fff;
								}
							}
						}

						&.date_select_show {
							height: 260px;
						}
					}

					.left_calendar_head {
						display: flex;
						align-items: center;
						background: rgba(91, 168, 151, 0.39);
						border-radius: 4px;
						margin-bottom: 9px;

						.calendar_head_item {
							flex: 1;
							// width: 14.2%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							line-height: 36px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
						}
					}

					.left_calendar_head :not(:last-child) {
						border-right: 1px solid #ffffff;
					}

					.left_calendar_content {
						display: flex;
						flex-wrap: wrap;

						.calendar_table_item {
							width: 115px;
							// width: 13.2%;
							height: 122px;
							background: #fafcfc;
							border-radius: 10px;
							margin-top: 10px;
							font-size: 16px;
							display: flex;
							flex-direction: column;
							cursor: pointer;
							box-sizing: border-box;
							position: relative;
							user-select: none;

							.table_item_day {
								padding: 9px 10px 5px 16px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								box-sizing: border-box;

								.table_item_val {
									width: 30px;
									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 30px;
									color: #333333;
									border-radius: 50%;
									text-align: center;

									&.date_active {
										color: #ffffff;
										background: $theme_color;
									}

									&.date_disable {
										color: rgba(51, 51, 51, 0.4);
									}
								}

								.table_item_icon {
									width: 15px;
									height: 15px;
								}
							}

							.table_item_task {
								height: 78px;
								overflow-x: auto;

								.item_task_inner {
									position: relative;

									.item_task_content {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 22px;
										padding-left: 11px;
										overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;
										box-sizing: border-box;
										border-radius: 0px 4px 4px 0px;
										margin-bottom: 2px;
									}

									.item_task_handler {
										width: 115px;
										height: 22px;
										position: absolute;
										display: flex;
										align-items: center;
										justify-content: center;
										background: rgba(0, 0, 0, 0.6);
										top: 0;
										left: 0;

										.task_edit {
											width: 18px;
											height: 18px;
											margin-right: 16px;
										}

										.task_delete {
											width: 18px;
											height: 18px;
										}
									}
								}
							}

							&.table_item_disable {
								background: #fdfefe;
							}

							&.table_item_active {
								border: 1px solid $theme_color;
							}
						}
					}

					.left_calendar_content :not(:nth-child(7n -6)).calendar_table_item {
						margin-left: 10px;
						// margin-left: 1.2%;
					}
				}
			}

			.dashboard_left_task {
				width: 200px;
				// width: 22%;
				padding-left: 10px;
				display: flex;
				flex-direction: column;
				margin-top: 4px;

				.left_task_btn {
					margin-left: auto;
					width: 138px;
					height: 38px;
					background: rgba(255, 255, 255, 0.39);
					box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.16);
					border-radius: 19px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					.task_btn_icon {
						width: 14px;
						height: 14px;
					}

					.task_btn_text {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						line-height: 24px;
						color: #666666;
						margin-left: 10px;
					}

					.task_btn_arrow {
						width: 18px;
						height: 18px;
					}
				}

				.left_task_head {
					background: $theme_color;
					border-radius: 4px;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					line-height: 36px;
					color: #ffffff;
					margin-top: 15px;
					text-align: center;
				}

				.left_task_box {
					flex: 1;
					display: flex;
					flex-direction: column;
					overflow: hidden;
					overflow-y: auto;

					.left_task_empty {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;

						img {
							width: 100px;
							height: 100px;
						}

						span {
							font-size: 12px;
							color: #999999;
						}
					}

					.left_task_title {
						display: flex;
						align-items: center;
						padding: 20px 0 16px 0;

						.task_title_point {
							width: 7px;
							height: 7px;
							background: rgba(0, 0, 0, 1);
							border-radius: 50%;
							margin-right: 8px;
						}

						.task_title_val {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #333333;
						}
					}

					.left_task_content {
						margin-bottom: 10px;

						.left_task_item {
							height: 72px;
							padding: 10px 10px;
							border-radius: 0px 4px 4px 0px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 24px;
							box-sizing: border-box;
							cursor: pointer;
							position: relative;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;

							.task_item_handle {
								width: 100%;
								height: 72px;
								position: absolute;
								display: flex;
								align-items: center;
								justify-content: center;
								background: rgba(0, 0, 0, 0.6);
								top: 0;
								left: 0;
								user-select: none;

								.task_edit {
									width: 24px;
									height: 24px;
									margin-right: 20px;
								}

								.task_delete {
									width: 24px;
									height: 24px;
								}
							}
						}
					}
				}
			}
		}

		.dashboard_right {
			width: 761px;
			// width: 41%;
			background: rgba(255, 255, 255, 0.39);
			border: 1px solid #eeeeee;
			border-radius: 4px;
			padding: 15px 20px;
			box-sizing: border-box;
			display: flex;

			.dashboard_right_student {
				margin-right: 15px;

				.right_student_title {
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					line-height: 31px;
					color: #333333;
					margin-bottom: 10px;
				}
			}

			.mt {
				margin: 10px 0;
			}

			.views_item_more {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				span {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #999999;
					margin-right: 10px;
				}

				img {
					width: 16px;
					height: 16px;

					&.showMore {
						transform: rotateX(180deg);
						-webkit-transform: rotateX(180deg);
					}
				}
			}

			.dashboard_right_content {
				flex: 1;

				.right_content_empty {
					height: 700px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					img {
						width: 200px;
						height: 200px;
					}

					span {
						font-size: 15px;
						color: #999999;
					}
				}

				.right_content_tab {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					.content_tab_btn {
						width: 118px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						line-height: 34px;
						color: #ffffff;
						text-align: center;
						margin-right: 20px;
					}

					.content_tab_list {
						flex: 1;
						display: flex;
						align-items: center;

						.content_tab_item {
							flex: 1;
							line-height: 30px;
							text-align: center;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							border: 1px solid #eeeeee;
							border-radius: 4px 4px 0px 0px;
							cursor: pointer;

							&.tab_item_active {
								background: rgba(91, 168, 151, 0.1);
								border-color: $theme_color;
								border-top: 1px solid $theme_color;
								font-weight: bold;
								color: $theme_color;
							}
						}
					}
				}

				.right_content_radio {
					display: flex;
					align-items: center;
					margin-bottom: 19px;

					.content_radio_item {
						display: flex;
						align-items: center;
						margin-right: 20px;
						cursor: pointer;

						.radio_item_icon {
							width: 16px;
							height: 16px;
							margin-right: 10px;
						}

						.radio_item_text {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 16px;
							color: #666666;
						}
					}
				}

				.right_content_precent {
					display: flex;
					align-items: center;
					height: 34px;
					line-height: 34px;
					border-radius: 4px;

					.content_precent_item {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}

					.precent_item_green {
						background: $theme_color;
						padding: 0 12px;
					}

					.precent_item_yellow {
						background: #d0743b;
						// flex: 1;
						padding: 0 12px;
					}
				}

				.right_content_university {
					display: flex;
					flex-direction: column;
					height: 750px;
					overflow: hidden;
					overflow-y: auto;

					.write-box {
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
						padding-top: 25px;
					}

					.university_item_label {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 14px;
						color: #999999;
						padding-right: 10px;
					}

					.university_item_value {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 14px;
						color: #333333;
					}

					.b-b {
						border: 1px solid #eeeeee;
						border-radius: 4px;
						padding: 0 20px;
					}

					.item_bottom_right {
						margin-left: auto;
						display: flex;
						flex-direction: column;
						text-align: center;

						.fs {
							font-size: 20px !important;
						}

						.university_item_percent {
							font-size: 30px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 30px;

							margin-bottom: 10px;
						}

						.university_item_text {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 14px;
							color: #999999;
						}
					}

					.content_university_item {
						// flex: 1;
						display: flex;
						flex-direction: column;
						margin-top: 14px;
						cursor: pointer;

						.university_item_name {
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							line-height: 16px;
							color: #333333;
							padding-bottom: 24px;
						}

						.university_item_bottom {
							// display: flex;
							padding-bottom: 15px;
							// background: red;
							background: #fafcfc;
							.item_bottom_left {
								display: flex;
								justify-content: space-between;
								align-items: flex-end;
								// background: #fafcfc;
								// flex-direction: column;
								// justify-content: flex-end;
								border-bottom: 1px solid #eeeeee;
								padding: 15px 0;

								&:last-child {
									border-bottom: none;
								}

								.university_item_time {
									display: flex;
									align-items: center;

									.university_item_label {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 14px;
										color: #999999;
										padding-right: 10px;
									}

									.university_item_value {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 14px;
										color: #333333;
									}
								}
							}
						}
					}
				}

				.right_views {
					height: 800px;
					overflow: hidden;
					overflow-y: auto;

					.right_views_item {
						background: #ffffff;
						border: 1px solid #eeeeee;
						border-radius: 4px;
						cursor: pointer;
						margin-bottom: 14px;

						.views_item_top {
							padding: 10px 20px;

							.views_item_name {
								margin-bottom: 8px;

								.item_name_left {
									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: 500;
									line-height: 24px;
									color: #333333;
									display: flex;
									align-items: center;

									.item_bottom_icon {
										width: 16px;
										line-height: 16px;
										padding: 2px;
										box-sizing: border-box;
										border: 1px solid #d03b3b;
										border-radius: 1px;
										text-align: center;
										font-size: 12px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #d03b3b;
										margin-left: 10px;
									}
								}

								.item_name_right {
									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 24px;

									&.agree {
										color: $theme_color;
									}

									&.reject {
										color: #d03b3b;
									}
								}
							}

							.views_item_content {
								.item_content_row {
									.content_row_item {
										flex: 1;
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 20px;
										color: #999999;
										margin-right: 20px;
										margin-bottom: 9px;

										.row_item_label {
											margin-right: 10px;
										}

										.row_item_value {}
									}

									.content_row_status {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 20px;
										color: #d0743b;
										margin-bottom: 9px;
									}
								}
							}
						}

						.views_item_bottom {
							background: #fafcfc;
							padding: 20px 20px;

							.item_bottom_row {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-bottom: 13px;

								.item_bottom_icon {
									width: 16px;
									height: 16px;
									margin-right: 6px;
									display: flex;
									align-items: center;
									justify-content: center;

									span {
										width: 16px;
										line-height: 16px;
										border: 1px solid #d03b3b;
										border-radius: 1px;
										text-align: center;
										font-size: 12px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #d03b3b;
									}
								}

								.item_bottom_label {
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 20px;
									color: #333333;
									margin-right: 10px;
								}

								.item_bottom_tag {
									line-height: 23px;
									border-radius: 1px;
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: $theme_color;
									padding: 3px 8px;
									background: #eef6f4;

									&.green {
										color: $theme_color;
										background: #eef6f4;
									}

									&.yellow {
										color: #d0743b;
										background: #faf1eb;
									}

									&.gray {
										color: #99a89c;
										background: #f5f6f5;
									}
								}

								.item_bottom_time {
									span {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 20px;
										color: #999999;
									}
								}
							}
						}
					}
				}

				.right_task {
					height: 750px;
					overflow: hidden;
					overflow-y: auto;

					.right_task_item {
						background: #ffffff;
						border: 1px solid #eeeeee;
						padding: 10px 20px;
						border-radius: 4px;
						margin-top: 14px;
						cursor: pointer;

						.mt {
							margin-top: 4px;
						}

						.task_item_top {
							margin-top: 33px;
						}

						.item_top_title {
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							line-height: 24px;
							color: #333333;
						}

						.item_top_status {
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 24px;
							color: #d0743b;

							&.green {
								color: $theme_color;
							}

							&.yellow {
								color: #d0743b;
							}
						}

						.task_item_bottom {
							.item_bottom_label {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: #999999;
								margin-right: 10px;
							}

							.item_bottom_time {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: #333333;
							}
						}
					}
				}
			}
		}
	}
</style>
